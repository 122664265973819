import React from "react";
import CtaBanner from "../../components/cta-banner";
import Layout from "../../components/layout";
import Review from "../../components/review";
import Link from "../../components/link";
import ServicesHero from "../../components/services/hero";
import Montage from "../../components/services/montage";
import PhotoLeft from "../../components/services/photo-left";
import PhotoRight from "../../components/services/photo-right";
import Faq from "../../components/services/faq";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const BusinessBrandingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			logoImg: file(relativePath: { eq: "logo.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			heroImg: file(relativePath: { eq: "services/business-branding.jpg" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			seymourImg: file(
				relativePath: { eq: "services/business-branding/seymour.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			miaImg: file(relativePath: { eq: "services/business-branding/mia.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			hazmatImg: file(
				relativePath: { eq: "services/business-branding/hazmat.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			dogsImg: file(
				relativePath: { eq: "services/business-branding/dogs.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			jamaciaImg: file(
				relativePath: { eq: "services/business-branding/jamacia.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			jewellersImg: file(
				relativePath: { eq: "services/business-branding/jewellers.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			vanImg: file(relativePath: { eq: "services/business-branding/van.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			ovalImg: file(
				relativePath: { eq: "services/business-branding/oval.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			chefsImg: file(
				relativePath: { eq: "services/business-branding/chefs.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			site {
				siteMetadata {
					siteUrl
					company
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const pageUrl = "/services/business-branding";
	const companyName = data.site.siteMetadata.company;
	const schemaImg = data.heroImg.publicURL;
	const logoImg = data.logoImg.publicURL;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Business Branding Photographer",
				item: `${siteUrl}/services/business-branding`,
			},
		],
	};

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}${pageUrl}#business-branding-photographer`,
		image: `${siteUrl}${schemaImg}`,
		mpn: `${siteUrl}`,
		sku: `${siteUrl}${pageUrl}`,
		description:
			"Business Branding Photography is a great way of showing your customers what goes on inside your company. See my portfolio for examples of my business photography.",
		logo: `${siteUrl}${logoImg}`,
		name: `${companyName}`,
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${logoImg}`,
			image: `${siteUrl}${schemaImg}`,
			name: `${companyName}`,
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: "24",
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/reviews`,
			datePublished: "2021-04-20T17:58:29+00:00",
			reviewBody:
				"Sarah put our staff completely at ease...The pics themselves are exactly what we wanted, couldn't be happier.",
			author: {
				"@type": "Person",
				name: "Paula Pollard",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: `${companyName}`,
				sameAs: `${siteUrl}`,
			},
		},
	};

	const faqs = [
		{
			question:
				"How do I prepare my staff for a Business Branding shoot with you?",
			answer:
				"I find the business owners who have the most successful business branding shoots are those who have prepared as much as possible. I don’t just mean by getting their hair done and choosing the right outfit for the day, but those who have prepared the groundwork in the office - and most importantly with their staff. Make sure your staff members know what to expect on the day, run through the schedule and talk about who will go first. It’s often a good idea to appoint someone to draw up this list and liaise with the photographer on the day. Try to sell it as a fun activity and reassure shyer members of staff that they can go last if they prefer. Also, tell them that they will be able to use their favourite headshot for their LinkedIn profile, this tends to motivate those who are less willing to embrace the shoot. Another key item to put on the agenda is stressing the importance of a business branding shoot for your company and how it can raise the company’s online profile and visually represent its values and help to build its brand, not to mention convey the all-important personalities of your team members.",
		},
		{
			question: "Should we clean the office beforehand?",
			answer:
				"Absolutely!  There is nothing worse than investing a lot of money in a shoot and then your office is dirty. You may want to employ a professional cleaner the night before to deep clean the office. If not, ask staff to clean their desks before they leave. Hide any clutter, dust computers, and remove any personal items like family photos and post it notes with personal details like names and numbers before the shoot. Tidy up cables and coats from the back of chairs and handbags on the floor, so your office is clean, tidy and inviting.",
		},
		{
			question: "What should everyone wear?",
			answer:
				"Ensure that all staff are wearing any company uniform, if appropriate. Remind your staff to make sure that their clothes are clean, tidy and ironed. If your staff don’t wear uniforms, ensure they arrive in clean, ironed and smart clothes that relate your company message. Block colours are best, so avoid any busy patterns. Don’t forget to make sure your hair is neat and tidy and if you’re a woman makeup is recommended, slightly more than you would normally wear during the day so you look your dazzling best under the lights. And whatever you do, don’t forget to have tidy nails!",
		},
		{
			question: "Whats in store for us, we’re very nervous of the camera?",
			answer:
				"There is no need to be nervous. I try to make it fun for everyone. I know most people hate having their photo taken. (Me included!) so I make it very simple and straight forward. I give direction and three simple poses for headshots. I run through this with everyone before I press any camera button. I always try and set up in a different room so there are no lookers on, and take my time and make sure everyone is relaxed and happy. For editorial shots around the office, I ask you to pretend I’m not there don’t worry about posing or looking at the camera, simply carry on as normal.",
		},
	];

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title="Business Branding Photographer In Sussex | Sarah Bennett"
				description="Business Branding Photography is a great way of showing your customers what goes on inside your company. See my portfolio for examples of my business photography."
				language="en"
				canonical="https://www.sarah-bennett.com/services/business-branding"
				openGraph={{
					url: "https://www.sarah-bennett.com/services/business-branding",
					title:
						"Business Branding Photogrpher| Sarah Bennett Commercial Photography",
					description: "Showcase your company to your customers",
					images: [
						{
							url: `${data.heroImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Professional Business Branding Photographer in East and West Sussex",
						},
					],
				}}
			/>
			<ServicesHero
				headline="Business Branding Photographer"
				subheadline=""
				img={data.heroImg.childImageSharp.gatsbyImageData}
				showBtn={true}
			/>
			<PhotoLeft
				img={data.seymourImg.childImageSharp.gatsbyImageData}
				heading="Get the right brand identity"
				text="Business photography is all about projecting the right brand identity for your company and making it come alive and be relatable to your customers. If you’re a savvy business owner, you’ll be all too aware of the value of developing your brand through great marketing photography. From your products and services, graphic design, business cards, and website photography, it’s all necessary to promote your brand."
			/>
			<PhotoRight
				img={data.miaImg.childImageSharp.gatsbyImageData}
				heading="Your personal brand"
				text={[
					<Link to="/services/personal-branding"> Personal branding </Link>,
					"photography is also very important for your website and social media. However, if being in front of the camera fills you with dread, fear not, we will always work with you so you feel comfortable as possible.",
				]}
			/>
			<Review
				author="Gemma Creasey"
				company="MD Oval Dene KBB Recruitment"
				quote="Thank you so much Sarah for your beautiful photos of the office and our team here at Oval Deene. I met Sarah at an awards ceremony earlier this year and her infectious personality and passion for photography led me to book our commercial photoshoot. Her communication is excellent, keeping in touch all the time and took the time to come and visit us before the shoot to get an idea of the space and our vision. She made the whole team feel very comfortable and has produced some pictures that are fantastic! Thank you again Sarah, I would highly recommend to any business out there looking for a commercial shoot. "
			/>
			<Montage
				img1={data.hazmatImg.childImageSharp.gatsbyImageData}
				img2={data.dogsImg.childImageSharp.gatsbyImageData}
				img3={data.jamaciaImg.childImageSharp.gatsbyImageData}
				img4={data.jewellersImg.childImageSharp.gatsbyImageData}
				img5={data.vanImg.childImageSharp.gatsbyImageData}
			/>
			<CtaBanner
				headline="Get in touch today for a free no-obligation quote"
				btnText="Get in Touch"
				url="/contact-us"
			/>
			<PhotoLeft
				img={data.ovalImg.childImageSharp.gatsbyImageData}
				heading="Tell your story"
				text="Commercial photography, or, corporate photography, is so important to portray your image, especially if you have recently made changes to your business premises or team. With the right business branding photography, even as a small business, you can define your brand image and tell the story of your company and its values, while giving your customers an insight into your working environment. In a world where so much business is now conducted online without ever meeting face-to-face, you need to stand out from the crowd."
			/>
			<PhotoRight
				img={data.chefsImg.childImageSharp.gatsbyImageData}
				heading="What's involved?"
				text="A typical business branding shoot will involve on-site photography of you and your staff in your offices or premises, usually posed to show them performing their daily tasks. So, whether you have recently undergone changes, or you’re a start-up, business branding photography is a must to let your customers into your world."
			/>
			<Review
				author="Mark Hadley"
				company="Shredded Neat"
				quote="Sarah is an award winning commercial photographer of whom we had seen a lot of her work before looking to use her, this year she won a national award for her services, but we were very impressed with high quality of her work and inventive style, providing great new content for our marketing resources, we couldn't recommend her more highly."
			/>
			<Faq faqs={faqs} />
			<CtaBanner
				headline="Refresh your company's image brand-new, bespoke photos that reflect where your business is now - and where you want it to go!"
				btnText="Book an appointment"
				url="/contact-us"
			/>
		</Layout>
	);
};

export default BusinessBrandingPage;
